import axios from 'axios';
import EventBus from 'eventing-bus';
import { toggleLoader } from "../actions/Auth";
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setMigration, setMigrations, setDownloadCSV, setFieldsMapping, setResults, setResultPages, setResultTotal, setErrors, setStripeSecret, updateErrorPopup, setModalObj } from "../actions/Migration";

function* getMigrations() {
  yield put(toggleLoader({ message: `Getting migrations...`, status: true }));
  const { error, response } = yield call(getCall, 'migrations');
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) yield put(setMigrations(response['data']['body']));
  yield put(toggleLoader({ message: ``, status: false }));
};

function* getMigration({ payload }) {
  yield put(toggleLoader({ message: `Getting migration...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/get/${payload['key']}`);
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) yield put(setMigration({ data: response['data']['body'], type: 'none' }));
  yield put(toggleLoader({ message: ``, status: false }));
};

function* newMigration({ payload }) {
  // const { error, response } = yield call(getCall, `migrations/create/${payload}`);
  const { error, response } = yield call(postCall, { path: 'migrations/create', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    yield put(setMigration({ data: response['data']['body'], type: 'redirect' }));
    EventBus.publish('success', response?.['data']?.['message']);
  };
};

function* setHubspotMapping({ payload }) {
  const { error } = yield call(postCall, { path: 'migrations/setHubspotMapping', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
};

function* connectPlatform({ payload }) {
  yield put(toggleLoader({ message: `Connecting to ${payload['platform']}...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/oAuth', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    yield put(setMigration({ data: response['data']['body'], type: 'redirect' }));
    EventBus.publish('success', response?.['data']?.['message']);
  };
  yield put(toggleLoader({ message: ``, status: false }));
};

function* setSelectedObjects({ payload }) {
  const { error, response } = yield call(postCall, { path: 'migrations/selectObjects', payload: payload || {} });
  if (error) {
    if (error?.['response']?.['data']?.['body']) yield put(setModalObj(error?.['response']?.['data']?.['body']?.['modal']));
    else EventBus.publish('error', error?.['response']?.['data']?.['message']);
  } else if (response) yield put(setMigration({ data: response['data']['body'], type: 'none' }));
};

function* downloadCSV({ payload }) {
  yield put(toggleLoader({ message: `Downloading ${payload['objectType']} fields...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/downloadFields', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    yield put(setDownloadCSV(response['data']['body']));
    EventBus.publish('success', response?.['data']?.['message']);
  };
  yield put(toggleLoader({ message: ``, status: false }));
};

function* uploadFields({ payload }) {
  yield put(toggleLoader({ message: `Uploading ${payload['objectType']} fields...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/uploadFields', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    // TODO: Show field uploading result
    EventBus.publish('success', response?.['data']?.['message']);
    yield put({ type: 'GET_FIELDS_MAPPING', payload: payload['key'] });
  };
  yield put(toggleLoader({ message: ``, status: false }));
};

function* getFieldsMapping({ payload }) {
  yield put(toggleLoader({ message: `Fetching field mapping...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/fieldMapping/${payload}`);
  if (error) {
    if (error?.['response']?.['data']?.['body']) yield put(setModalObj(error?.['response']?.['data']?.['body']?.['modal']));
    else EventBus.publish('error', error?.['response']?.['data']?.['message']);
  } else if (response) yield put(setFieldsMapping(response['data']['body']));
  yield put(toggleLoader({ message: ``, status: false }));
};

function* removeField({ payload }) {
  yield put(toggleLoader({ message: `Removing Field...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/removeField', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) EventBus.publish('success', response?.['data']?.['message']);
  yield put({ type: 'GET_FIELDS_MAPPING', payload: payload['key'] });
};

function* restartObject({ payload }) {
  yield put(toggleLoader({ message: `Restarting ${payload['label']} object...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/restartObject', payload: payload || {} });
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) EventBus.publish('success', response?.['data']?.['message']);
  yield put(toggleLoader({ message: ``, status: false }));
}

function* nextFieldMapping({ payload }) {
  yield put(toggleLoader({ message: `Checking field mapping...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/nextMapping/${payload}`);
  if (error) {
    if (error?.['response']?.['data']?.['body']) yield put(setModalObj(error?.['response']?.['data']?.['body']?.['modal']));
    else EventBus.publish('error', error?.['response']?.['data']?.['message']);
  } else if (response) yield put(setMigration({ data: response['data']['body'], type: 'none' }));
  yield put(toggleLoader({ message: ``, status: false }));
};

function* setupSample({ payload }) {
  yield put(toggleLoader({ message: `Run Sample Migration...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/runSample/${payload}`);
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    window.location.replace('/Dashboard');
    // yield put({ type: 'GET_MIGRATION', payload: { key: payload } });
  }
  yield put(toggleLoader({ message: ``, status: false }));
};

function* setupFullMigration({ payload }) {
  yield put(toggleLoader({ message: `Running Full Migration...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/runFullMigration/${payload}`);
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    yield put({ type: 'GET_MIGRATIONS' });
    yield put({ type: 'GET_MIGRATION', payload: { key: payload } });
    EventBus.publish('success', response?.['data']?.['message']);
  };
  yield put(toggleLoader({ message: ``, status: false }));
};

function* setDeleteMigration({ payload }) {
  yield put(toggleLoader({ message: `Deleting Migration...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/deleteMigration/${payload}`);
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    EventBus.publish('success', response?.['data']?.['message']);
    yield put({ type: 'GET_MIGRATIONS' });
  }
  yield put(toggleLoader({ message: ``, status: false }));
};

function* getResults({ payload }) {
  yield put(toggleLoader({ message: `Getting Results...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/getResults/${payload['key']}/${payload['objectName']}?search=${payload['search']}&page=${payload['page']}`);
  yield put(toggleLoader({ message: ``, status: false }));
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    yield put(setResults(response['data']['body']['results']));
    yield put(setResultTotal(response['data']['body']['total']));
    yield put(setResultPages(response['data']['body']['pages']));
  };
};

function* getErrors({ payload }) {
  yield put(toggleLoader({ message: `Getting Errors...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/getErrors/${payload['key']}`);
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) yield put(setErrors(response['data']['body']));
  yield put(toggleLoader({ message: ``, status: false }));
};

function* getStripeSecret({ payload }) {
  yield put(toggleLoader({ message: `Getting Stripe secret...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/getStripeSecret/${payload['key']}`);
  if (error) EventBus.publish('error', error?.['response']?.data?.['message']);
  else if (response) yield put(setStripeSecret(response['data']['body']));
  yield put(toggleLoader({ message: ``, status: false }));
};

function* createRelations({ payload }) {
  yield put(toggleLoader({ message: `Starting objects relations...`, status: true }));
  const { error, response } = yield call(getCall, `migrations/runRelationMigration/${payload}`);
  yield put(toggleLoader({ message: ``, status: false }));
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else {
    yield put({ type: 'GET_MIGRATIONS' });
    yield put({ type: 'GET_MIGRATION', payload });
    EventBus.publish('success', response?.['data']?.['message']);
  }
};

function* setPaymentStatus({ payload }) {
  const { error, response } = yield call(getCall, `migrations/paymentCompleted/${payload}`);
  // if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  if(!error) EventBus.publish('success', response?.['data']?.['message']);
};

function* saveNewMapping({ payload }) {
  yield put(toggleLoader({ message: `Creating new ${payload['objectType']} field mapping...`, status: true }));
  const { error, response } = yield call(postCall, { path: `migrations/saveNewMapping/${payload['objectType']}`, payload });
  yield put(toggleLoader({ message: ``, status: false }));
  if (error) yield put(updateErrorPopup(error?.['response']?.['data']?.['message']));
  else if (response) {
    EventBus.publish('success', response?.['data']?.['message']);
    yield put({ type: 'GET_FIELDS_MAPPING', payload: payload['migrationKey'] });
  }
};

function* addHubspotProperty({ payload }) {
  yield put(toggleLoader({ message: `Creating ${payload['actionType']} hubspot property...`, status: true }));
  const { error, response } = yield call(postCall, { path: `migrations/addProperty/${payload['objectType']}`, payload });
  yield put(toggleLoader({ message: ``, status: false }));
  if (error) yield put(updateErrorPopup(error?.['response']?.['data']?.['message']));
  else if (response) {
    EventBus.publish('success', response?.['data']?.['message']);
    yield put({ type: 'GET_FIELDS_MAPPING', payload: payload['key'] });
  }
};

function* downloadFile({ payload }) {
  yield put(toggleLoader({ message: `Exporting ${payload['objectType']} records...`, status: true }));
  const { error, response } = yield call(postCall, { path: `migrations/downloadFile`, payload });
  yield put(toggleLoader({ message: ``, status: false }));
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) {
    EventBus.publish('success', response?.['data']?.['message']);
    if(response?.['data']?.['body']) window.open(response?.['data']?.['body'], '_blank').focus();
  }
};

function* deleteObject({ payload }) {
  yield put(toggleLoader({ message: `Deleting ${payload['name']} HubSpot records...`, status: true }));
  const { error, response } = yield call(postCall, { path: `migrations/deleteObject`, payload });
  yield put(toggleLoader({ message: ``, status: false }));
  if (error) EventBus.publish('error', error?.['response']?.['data']?.['message']);
  else if (response) EventBus.publish('success', response?.['data']?.['message']);
};


function* actionWatcher() {
  yield takeEvery('GET_ERRORS', getErrors);
  yield takeEvery('GET_RESULTS', getResults);
  yield takeEvery('DOWNLOAD_CSV', downloadCSV);
  yield takeEvery('REMOVE_FIELD', removeField);
  yield takeEvery('SETUP_SAMPLE', setupSample);
  yield takeEvery('NEW_MIGRATION', newMigration);
  yield takeEvery('GET_MIGRATION', getMigration);
  yield takeEvery('UPLOAD_FIELDS', uploadFields);
  yield takeEvery('DOWNLOAD_FILE', downloadFile);
  yield takeEvery('DELETE_OBJECT', deleteObject);
  yield takeEvery('RESERT_OBJECT', restartObject);
  yield takeEvery('GET_MIGRATIONS', getMigrations);
  yield takeEvery('SAVE_NEW_MAPPING', saveNewMapping);
  yield takeEvery('CONNECT_PLATFORM', connectPlatform);
  yield takeEvery('CREATE_RELATIONS', createRelations);
  yield takeEvery('GET_STRIPE_SECRET', getStripeSecret);
  yield takeEvery('SET_PAYMENT_STATUS', setPaymentStatus);
  yield takeEvery('GET_FIELDS_MAPPING', getFieldsMapping);
  yield takeEvery('NEXT_FIELD_MAPPING', nextFieldMapping);
  yield takeEvery('SET_HUBSPOT_MAPPING', setHubspotMapping);
  yield takeEvery('SET_SELECTED_OBJECTS', setSelectedObjects);
  yield takeEvery('SETUP_FULL_MIGRATION', setupFullMigration);
  yield takeEvery('SET_DELETE_MIGRATION', setDeleteMigration);
  yield takeEvery('ADD_HUBSPOT_PROPERTY', addHubspotProperty);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error?.response?.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error?.response?.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
