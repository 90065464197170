import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { setPaymentStatusAction, getMigration } from '../../store/actions/Migration';

const PostPayment = (props) => {
    const stripe = useStripe();
    const dispatch = useDispatch();

    const {
        modalSetter: setModal,
        notificationSetter: setNotification,
        fullMigrationSetter: setPaymentStatus,
        notificationMessageSetter: setNotificationMessage,
        notificationSeveritySetter: setNotificationSeverity,
    } = props;
    
    const fireNotification = (status = 'success', message = 'Success! Payment received.') => {
        setNotificationSeverity(status);
        setNotificationMessage(message);
        setTimeout(() => setNotification(true), 3000);
    };

    useEffect(() => {
        if (!stripe) return;
        const urlSearchParams = new URLSearchParams(window['location']['search']);
        const migrationKey = urlSearchParams.get('migration');
        const stripePISecret = urlSearchParams.get('payment_intent_client_secret');
        
        stripe.retrievePaymentIntent(stripePISecret).then(({ paymentIntent }) => {
            setModal(true);
            dispatch(getMigration({ key: migrationKey, type: 'none' }));
            switch (paymentIntent.status) {
                case 'succeeded':
                    setPaymentStatus('paid');
                    fireNotification('success', 'Success! Payment received.');
                    dispatch(setPaymentStatusAction(urlSearchParams.get('migration')));
                    break;

                case 'processing':
                    setPaymentStatus('processing');
                    fireNotification('info', "Payment processing. We'll update you when payment is received.");
                    break;

                case 'requires_payment_method':
                    setPaymentStatus('failed');
                    fireNotification('error', 'Payment failed. Please try another payment method.')
                    break;

                default:
                    break;
            }
        });
    }, [stripe]);
};

export default PostPayment;