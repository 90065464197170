/* -- set app title --*/
const AppTitle = 'FRONTEND MIGRATR';

/*-- set app mode --*/
// const AppMode = [''];
const AppMode = ['production'];
// const AppMode = ['development'];

/* -- set API URLs --*/
const development = 'https://oldmigratr.instrumental.net';
const production = 'https://oldmigratrv2.instrumental.net';

let ApiUrl, baseURL, env = AppMode[0];
switch (AppMode[0]) {
  case 'development':
    ApiUrl = development;
    baseURL = 'https://migratr.instrumental.net';
    break;
  case 'production':
    ApiUrl = production;
    baseURL = 'https://migratr.instrumental.net';
    break;
  default:
    ApiUrl = 'http://localhost:3000';
    baseURL = 'http://localhost:4000';
};
ApiUrl = `${ApiUrl}/api/`;
console.log('****** ApiUrl = ', ApiUrl);
export { AppTitle, ApiUrl, baseURL, env };